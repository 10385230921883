import React from "react"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import Footer from "../components/Footer"
import BlogDetails from "../components/BlogDetails"
import Nav from "../components/Nav"
import { graphql } from "gatsby"

export const query = graphql`
    query($id: String!) {
        allStrapiBlogArticle(filter: {id: {eq: $id}}) {
            nodes {
                id
                content
                image {
                    sharp: childImageSharp {
                        fluid( maxWidth: 770 ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                slug
                title
                shortdescription
                metadescription
                categories {
                    id
                    name
                    slug
                }
                updated_at
            }
        }
        ultimos:allStrapiBlogArticle(limit: 3, sort: {fields: updated_at, order: DESC}) {
            nodes {
                id
                slug
                title
                image {
                    sharp: childImageSharp {
                        fluid( maxWidth: 66 ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`
const BlogDetailPage = ({ data }) => {
  const blogContent = data.allStrapiBlogArticle.nodes[0]
  const lastArticles = data.ultimos.nodes;
  return (
    <Layout
      title={"Rolteca | " + blogContent.title}
      description={blogContent.metadescription}
      slug={blogContent.slug+"/"}
      image={blogContent.image}>
      <Nav />
      <PageHeader title={blogContent.title} />
      <BlogDetails
        blogContent={blogContent}
        lastArticles={lastArticles}/>
      <Footer />
    </Layout>
  )
}

export default BlogDetailPage
