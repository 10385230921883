import React from "react";
import author1 from "../assets/images/author-1-1.jpg";
import { Link } from "gatsby"
import Img from "gatsby-image"
import AdsSection from "./AdsSection"

const BlogDetails = ({blogContent, lastArticles}) => {
  return (
    <section className="blog-details">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="blog-one__single">
              <div className="blog-one__image">
                <Img fluid={blogContent.image.sharp.fluid} alt={blogContent.title} />
              </div>
              <div className="blog-one__content text-justify">
                <span className="blog-one__text">
                  <div dangerouslySetInnerHTML={{__html: blogContent.content}} />
                </span>
              </div>
            </div>
            <div className="share-block">
              <div className="left-block">
                <p>
                 Compartir:
                </p>
              </div>
              <div className="social-block">
                <a target="_blank" rel="noreferrer" href={"https://twitter.com/intent/tweet?text=https://rolteca.com/" + blogContent.slug}>
                  <i className="fab fa-twitter"></i>
                </a>
                <a target="_blank" rel="noreferrer" href={"https://www.facebook.com/dialog/share?app_id=1035471519867457&display=popup&href=https%3A%2F%2Frolteca.com%2F%2F"+blogContent.slug}>
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a target="_blank" rel="noreferrer" href={"https://telegram.me/share/url?url=https://rolteca.com" + blogContent.slug}>
                  <i className="fab fa-telegram"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="sidebar">
              <div className="sidebar__single sidebar__post">
                <h3 className="sidebar__title">Últimos articulos</h3>
                <div className="sidebar__post-wrap">
                  {lastArticles.map(article =>(
                    <div key={article.id} className="sidebar__post__single">
                      <div className="sidebar__post-image">
                        <div className="inner-block">
                          <Img fluid={article.image.sharp.fluid} alt={article.title} />
                        </div>
                      </div>
                      <div className="sidebar__post-content">
                        <h4 className="sidebar__post-title">
                          <Link to={"https://rolteca.com"+article.slug}>
                            {article.title}
                          </Link>
                        </h4>
                      </div>
                    </div>
                    )
                  )}
                </div>
              </div>
              <AdsSection/>
              <div className="sidebar__single sidebar__category">
                <h3 className="sidebar__title">Categorías</h3>
                <ul className="sidebar__category-list">
                  {blogContent.categories.map( category =>(
                    <li key={category.id} id={category.id} className="sidebar__category-list-item">
                    <Link to={category.slug}> {category.name} </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetails;
