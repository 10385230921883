import React from "react"

function AdsSection(props) {
  return (
    <div className="sidebar__single sidebar__tags">
      <h2 className="sidebar__title">Espacio patrocinado</h2>
      <p>¿Quieres aparecer aquí? contacta con nosotros en <a href="mailto:contacto@rolteca.com"> nuestro correo </a> </p>
    </div>
  )
}

export default AdsSection
